// import Banner from '../components/banner'
// import Companies from '../components/companies'
// import Video from '../components/video'
// import Features from '../components/features'
import dynamic from 'next/dynamic'

const Banner = dynamic(() => import('../components/banner'))
const Companies = dynamic(() => import('../components/companies'))
const Video = dynamic(() => import('../components/video'))
const Features = dynamic(() => import('../components/features'))

export default function Home() {
  return (
    <div>
      <Banner />
      <Companies />
      <Video />
      <Features />
    </div>
  )
}
